:root {
  --p: 10px;
  --h: calc(var(--p) / 2);
  --q: calc(var(--p) / 4);
  --c-highlight: #fedb4a;
  --c-boundary: #f6f6f6;
  --c-muted: #888;
  --green: #8bc34a;
  --blue: #64b5f6;
  --red: #f44336;
  --purple: #673ab7;
  --grey: #cfd8dc;
  --orange: #ff8f00;
  --teal: #009688;
  --standard-border: 2px solid var(--c-boundary);
  --button-size: calc(1rem * (5/6));
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 16px;
  overflow-y: hidden;
}

body {
  display: grid;
  grid-template-columns: 20px auto 1fr auto;
  grid-template-rows: calc(100vh - 20px) 20px;
  max-height: 100vh;
  margin: 0;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

button:focus, input:focus, textarea:focus {
  outline: none;
}

button {
  font-size: var(--button-size);
  cursor: pointer;
  background-color: transparent;
}

.login-fields {
  grid-column: 1/4;
  grid-row: 1/4;
  justify-self: center;
  align-self: center;
  width: 400px;
  max-width: 400px;
  border: var(--standard-border);
  box-sizing: border-box;
  padding: calc(var(--p) * 2);
}
.login-fields p {
  margin-top: 0;
}
.login-fields label, .login-fields input, .login-fields button {
  display: block;
}
.login-fields label {
  font-size: 0.9rem;
}
.login-fields input {
  width: 100%;
  border: none;
  margin: 5px 0 20px 0;
  padding: 5px;
  box-sizing: border-box;
  background-color: var(--c-boundary);
}
.login-fields button {
  float: right;
  background-color: var(--c-highlight);
  border: 0;
  padding: 5px 10px;
}
.login-fields button.forgot-password {
  float: left;
  background-color: transparent;
  padding: 5px 0;
  color: var(--c-muted);
  text-decoration: underline;
}
.login-fields button:hover {
  text-decoration: underline;
}
.login-fields .login-error {
  display: block;
  clear: both;
  color: red;
  font-weight: 700;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding-top: 10px;
}

div.main-menu-toggle {
  grid-column: 1/2;
  grid-row: 1/3;
  background-color: var(--c-boundary);
  cursor: pointer;
}
div.main-menu-toggle:hover {
  background-color: var(--c-highlight);
}

nav.main-menu {
  grid-column: 2/3;
  grid-row: 1/3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  box-sizing: border-box;
  font-size: var(--button-size);
  border-right: var(--standard-border);
  padding: var(--p);
}
nav.main-menu button, nav.main-menu a {
  display: block;
  padding: 0 0 5px 0;
  margin: 0;
  width: 100%;
  text-align: left;
  border: 0;
  background-color: transparent;
  text-decoration: none;
  color: #000;
}
nav.main-menu button.indent, nav.main-menu a.indent {
  padding-left: var(--p);
}
nav.main-menu button:hover span, nav.main-menu button.here span, nav.main-menu a:hover span, nav.main-menu a.here span {
  background-color: var(--c-highlight);
}
nav.main-menu hr {
  display: block;
  height: 2px;
  margin: 5px 0 10px 0;
  padding: 0;
  border: none;
  background-color: var(--c-boundary);
}

div.main {
  grid-column: 3/4;
  grid-row: 1/3;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto 1fr;
  align-self: start;
  font-size: 0.9rem;
  height: 100vh;
}
div.main .controls {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto auto auto auto;
  border-bottom: var(--standard-border);
}
div.main .controls input[type=text] {
  border: 0;
  padding: var(--p);
  width: 100%;
  box-sizing: border-box;
}
div.main .controls .counts {
  padding: var(--p);
  font-family: monospace;
  font-weight: 600;
}
div.main .controls .counts span {
  font-weight: 300;
}
div.main .controls div.pagination {
  padding: var(--p) 0;
  font-size: var(--button-size);
}
div.main .controls button {
  border: none;
  padding: var(--p);
}
div.main .controls button.green {
  background-color: var(--green);
}
div.main .controls button.grey {
  background-color: var(--grey);
}
div.main .controls button.filter {
  display: flex;
  flex-direction: column;
  align-items: end;
}
div.main .controls button.filter:hover {
  text-decoration: none;
  background-color: var(--c-boundary);
}
div.main .controls button.filter.active {
  background-color: var(--c-boundary);
}
div.main .controls button.pagination {
  color: var(--grey);
}
div.main .controls button.pagination:hover {
  text-decoration: none;
  color: black;
}
div.main .controls button:hover {
  text-decoration: underline;
}
div.main .filters {
  grid-row: 2/3;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
}
div.main .filters .filter {
  padding: var(--p);
}
div.main .filters .filter label {
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--c-muted);
}
div.main .filters .filter .select .input {
  background-color: var(--c-boundary);
}
div.main .filters .filter .text {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
}
div.main .filters .filter .text > input[type=text] {
  background-color: var(--c-boundary);
  border: 0;
  padding: var(--h);
  display: block;
  width: calc(100% - 2*var(--h));
}
div.main .filters .filter .text button {
  border: none;
  font-size: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  background-color: var(--c-boundary);
  padding: var(--h) var(--p) 0 var(--p);
}
div.main .filters .filter .text button:hover {
  background-color: #fedb4a;
}
div.main .row.clickable {
  cursor: pointer;
}
div.main .table {
  grid-column: 1/2;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}
div.main .table.table-header {
  grid-row: 3/4;
}
div.main .table.main-collection {
  grid-row: 4/5;
}
div.main .table .row {
  border-bottom: 1px solid var(--c-boundary);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
}
div.main .table .row:hover {
  background-color: var(--c-boundary);
}
div.main .table .row.archived {
  background-image: linear-gradient(45deg, var(--c-highlight) 8.33%, #ffffff00 8.33%, #ffffff00 50%, var(--c-highlight) 50%, var(--c-highlight) 58.33%, #ffffff00 58.33%, #ffffff00 100%);
  background-size: 6px 6px;
}
div.main .table .row .key {
  display: none;
}
div.main .table .row .col {
  padding: var(--p);
  border-right: 1px solid var(--c-boundary);
  overflow-wrap: break-word;
}
div.main .table .row .col.monospace {
  font-family: monospace;
}
div.main .table .row .col.jar-of-jellybeans {
  padding-top: calc(var(--p) - 3px);
  padding-bottom: 0;
  padding-left: calc(var(--p) - 6px);
  padding-right: calc(var(--p) - 6px);
}
div.main .table .row .col.jar-of-jellybeans .jellybean {
  display: inline-block;
  margin: 0 calc(var(--p) - 3px) calc(var(--p) - 3px) 0;
  border: 1px solid var(--c-boundary);
  border-radius: 5px;
  padding: 2px 5px;
  background-color: var(--c-boundary);
}
div.main .table .row .row-data {
  display: grid;
  grid-template-rows: auto;
}
div.main .table .row .row-buttons {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: auto;
}
div.main .table .row .row-buttons button {
  border: none;
  padding: var(--p);
}
div.main .table .row .row-buttons button.blue {
  background-color: var(--blue);
}
div.main .table .row .row-buttons button.red {
  background-color: var(--red);
}
div.main .table .row .row-buttons button:hover {
  text-decoration: underline;
}
div.main .table .row.being-edited {
  background-color: var(--c-highlight);
}
div.main .table .header-row {
  background-color: var(--c-boundary);
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
}
div.main .table .header-row .col {
  padding: calc(var(--p) / 2) var(--p);
  border-right: 1px solid var(--c-boundary);
}
div.main .table .header-row .row-buttons button {
  padding: calc(var(--p) / 2) var(--p);
}
div.main .masonry {
  grid-row: 4/5;
  padding: var(--p) 0 0 var(--p);
  background-color: var(--c-boundary);
}
div.main .masonry .row-sizer {
  display: block;
  width: calc(100% / 4);
}
div.main .masonry .row {
  display: block;
  box-sizing: border-box;
  float: left;
  width: calc(((100% - 4 * var(--p))/ 4));
  padding: var(--p);
  border: 1px solid #fff;
  margin-right: var(--p);
  margin-bottom: var(--p);
  background-color: #fff;
}
div.main .masonry .row:hover {
  border: 1px solid #ddd;
}
div.main .masonry .row.archived {
  background-image: linear-gradient(45deg, var(--c-highlight) 8.33%, #ffffff00 8.33%, #ffffff00 50%, var(--c-highlight) 50%, var(--c-highlight) 58.33%, #ffffff00 58.33%, #ffffff00 100%);
  background-size: 6px 6px;
}
div.main .masonry .row .key {
  grid-column: 1/2;
  border-width: 1px 0 0 0;
  border-color: var(--c-boundary);
  border-style: solid;
  padding: calc(var(--p) / 2);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-family: monospace;
  line-height: 0.9rem;
}
div.main .masonry .row .key:first-of-type {
  display: none;
}
div.main .masonry .row .col {
  grid-column: 2/3;
  border-width: 1px 0 0 1px;
  border-color: var(--c-boundary);
  border-style: solid;
  padding: calc(var(--p) / 2);
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.main .masonry .row .col::before {
  content: attr(data-col);
}
div.main .masonry .row .col:first-of-type {
  grid-column: 1/3;
  margin-bottom: var(--p);
  border: none;
  font-weight: 700;
  word-wrap: break-all;
}
div.main .masonry .row .col.monospace {
  font-family: monospace;
}
div.main .masonry .row .col.bold {
  font-weight: 700;
}
div.main .masonry .row .row-data {
  display: grid;
  grid-template-columns: auto 1fr;
  border-bottom: 1px solid var(--c-boundary);
}
div.main .masonry .row .row-buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--p);
  opacity: 0;
}
div.main .masonry .row .row-buttons button {
  border: none;
  padding: var(--p) 0 0 var(--p);
}
div.main .masonry .row .row-buttons button.blue {
  background-color: var(--blue);
}
div.main .masonry .row .row-buttons button.red {
  background-color: var(--red);
}
div.main .masonry .row .row-buttons button:hover {
  text-decoration: underline;
}
div.main .masonry .row:hover .row-buttons {
  opacity: 1;
}
div.main .masonry .row.being-edited {
  border: 1px solid #000;
}

aside {
  box-sizing: border-box;
  width: 40vw;
  max-width: 700px;
  border-left: var(--standard-border);
  grid-column: 4/5;
  grid-row: 1/3;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  font-size: 0.9rem;
}
aside .controls {
  grid-column: 1/2;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  border-top: var(--standard-border);
  border-bottom: var(--standard-border);
}
aside .controls:first-of-type {
  border-top: none;
}
aside .controls h1 {
  padding: var(--p);
  font-weight: 600;
  font-size: var(--button-size);
  margin: 0;
}
aside .controls .buttons {
  display: flex;
}
aside .controls button {
  border: none;
  padding: var(--p);
}
aside .controls button.green {
  background-color: var(--green);
}
aside .controls button.blue {
  background-color: var(--blue);
}
aside .controls button:hover {
  text-decoration: underline;
}
aside .fields {
  padding: var(--p);
}
aside .fields > div > input[type=text], aside .fields input[type=date], aside .fields input[type=color] {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid var(--c-boundary);
  background-color: var(--c-boundary);
  padding: 5px;
  font-size: 14px;
}
aside .fields > div > input[type=color] {
  height: calc(4 * var(--p));
}
aside .fields > div > .switch-container {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid var(--c-boundary);
  background-color: var(--c-boundary);
  padding: 5px;
}
aside .fields select {
  display: block;
  border: 0;
  background-color: #fcfcfc;
  padding: 5px;
  font-size: 14px;
}
aside .fields select:focus {
  outline: none;
}
aside .fields textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid var(--c-boundary);
  background-color: var(--c-boundary);
  padding: 5px;
  font-size: 14px;
  height: 100px;
  resize: vertical;
}
aside .fields textarea:focus {
  outline: none;
}
aside .fields label {
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--c-muted);
  margin-bottom: 10px;
}
aside .fields label button.inline {
  display: inline;
  border: 0;
  padding: 0;
  font-size: inherit;
  text-decoration: underline;
}
aside .fields .error > input[type=text], aside .fields .error input[type=date], aside .fields .error input[type=date] {
  border: 1px solid var(--red);
}
aside .fields .error > .select {
  border: 1px solid var(--red);
}
aside .fields .error > label {
  color: var(--red);
}
aside .fields .field-box.monospace input {
  font-family: monospace;
}
aside .fields .sortable {
  background-color: var(--c-boundary);
}
aside .fields .sortable .sortable-drag .choices__list {
  display: none;
}
aside .fields .sortable .item {
  display: flex;
  flex-flow: wrap;
}
aside .fields .sortable .item:last-of-type {
  border-bottom: none;
}
aside .fields .sortable .item .item-content {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto auto;
}
aside .fields .sortable .item .item-content .content, aside .fields .sortable .item .item-content .group {
  padding: var(--p) var(--p) calc(var(--p)/2) var(--p);
  flex-grow: 1;
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
aside .fields .sortable .item .item-content .content .subfield-box, aside .fields .sortable .item .item-content .group .subfield-box {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: calc(var(--p) / 2);
}
aside .fields .sortable .item .item-content .content .subfield-box label, aside .fields .sortable .item .item-content .group .subfield-box label {
  display: none;
}
aside .fields .sortable .item .item-content .content .subfield-box input[type=text], aside .fields .sortable .item .item-content .group .subfield-box input[type=text] {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid #fcfcfc;
  background-color: #fcfcfc;
  padding: 5px;
  font-size: 14px;
}
aside .fields .sortable .item .item-content .content .subfield-box input[type=text]::placeholder, aside .fields .sortable .item .item-content .group .subfield-box input[type=text]::placeholder {
  color: #cdcdcd;
}
aside .fields .sortable .item .item-content .content .subfield-box input[type=text].choices__input, aside .fields .sortable .item .item-content .group .subfield-box input[type=text].choices__input {
  padding: 10px;
  border-bottom: 1px solid #d0d0d0;
}
aside .fields .sortable .item .item-content .content .subfield-box .choices .choices__inner, aside .fields .sortable .item .item-content .group .subfield-box .choices .choices__inner {
  background-color: #fcfcfc;
}
aside .fields .sortable .item .item-content .content .subfield-box .choices .choices__inner .choices__list, aside .fields .sortable .item .item-content .group .subfield-box .choices .choices__inner .choices__list {
  padding-right: 45px;
}
aside .fields .sortable .item .item-content .content .subfield-box .item .item-content, aside .fields .sortable .item .item-content .group .subfield-box .item .item-content {
  padding-bottom: var(--h);
}
aside .fields .sortable .item .item-content .content .subfield-box .item .item-content .content, aside .fields .sortable .item .item-content .group .subfield-box .item .item-content .content {
  display: grid;
  grid-template-columns: repeat(20, 0.05fr);
  padding: 0;
}
aside .fields .sortable .item .item-content .content .subfield-box .insert, aside .fields .sortable .item .item-content .group .subfield-box .insert {
  background-color: #fcfcfc;
}
aside .fields .sortable .item .item-content .content .subfield-box .insert:hover, aside .fields .sortable .item .item-content .group .subfield-box .insert:hover {
  color: #fcfcfc;
  background-color: #141414;
}
aside .fields .sortable .item .item-content .content .subfield-box .buttons .button, aside .fields .sortable .item .item-content .group .subfield-box .buttons .button {
  padding: var(--h) var(--p);
}
aside .fields .sortable .item .item-content .group {
  padding: var(--p);
  border-top: 1px solid #fff;
  grid-column: 1/4;
  display: grid;
  grid-template-columns: repeat(20, 0.05fr);
}
aside .fields .sortable .item .item-content .group .subfield-box {
  margin: 0;
}
aside .fields .sortable .item .item-content .group.logic {
  border: 1px solid #673ab7;
}
aside .fields .sortable .item .item-content .group.logic .insert:hover {
  background-color: #673ab7;
}
aside .fields .sortable .item .item-content .group.required {
  border: 1px solid var(--orange);
}
aside .fields .sortable .item .item-content .group.contact {
  border: 1px solid var(--teal);
}
aside .fields .sortable .item .item-content .buttons {
  display: flex;
}
aside .fields .sortable .item .item-content .buttons .button {
  padding: var(--p);
  display: flex;
  align-items: center;
  cursor: pointer;
}
aside .fields .sortable .item .item-content .buttons .delete {
  color: var(--red);
}
aside .fields .sortable .item .item-content .buttons .delete:hover {
  background-color: var(--red);
  color: #fff;
}
aside .fields .sortable .item .item-content .buttons .logic {
  color: var(--purple);
}
aside .fields .sortable .item .item-content .buttons .logic:hover, aside .fields .sortable .item .item-content .buttons .logic.active {
  background-color: var(--purple);
  color: #fff;
}
aside .fields .sortable .item .item-content .buttons .required {
  color: var(--orange);
}
aside .fields .sortable .item .item-content .buttons .required:hover, aside .fields .sortable .item .item-content .buttons .required.active {
  background-color: var(--orange);
  color: #fff;
}
aside .fields .sortable .item .item-content .buttons .contact {
  color: var(--teal);
}
aside .fields .sortable .item .item-content .buttons .contact:hover, aside .fields .sortable .item .item-content .buttons .contact.active {
  background-color: var(--teal);
  color: #fff;
}
aside .fields .sortable .item .item-content .handle {
  width: calc(3 * var(--p));
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==) repeat;
  border: 5px solid var(--c-boundary);
  box-sizing: border-box;
  cursor: grab;
}
aside .fields .sortable .item .insert {
  width: 100%;
}
aside .fields .sortable .insert {
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}
aside .fields .sortable .insert:hover {
  background-color: var(--c-highlight);
}
aside .fields select {
  width: 100%;
  height: 100%;
}
aside .messages .message {
  padding: var(--p);
  border: 1px solid var(--red);
  font-weight: 700;
  text-align: center;
  color: var(--red);
  background-color: #f4433644;
}

.shades {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: #ffffffee;
}
.shades .modal {
  justify-self: center;
  align-self: center;
  width: 400px;
  max-width: 400px;
  background-color: #fff;
  border: var(--standard-border);
  box-sizing: border-box;
  padding: calc(var(--p) * 2);
}
.shades .modal.large {
  width: 700px;
  max-width: 700px;
}
.shades .modal p {
  margin-top: 0;
}
.shades .modal button {
  float: right;
  background-color: var(--c-highlight);
  border: 0;
  padding: 5px 10px;
  margin-left: var(--p);
}
.shades .modal button.muted {
  float: left;
  background-color: transparent;
  padding: 5px 0;
  color: var(--c-muted);
  text-decoration: underline;
  margin-left: 0;
}
.shades .modal button:hover {
  text-decoration: underline;
}
.shades .modal button.red {
  background-color: var(--red);
}
.shades .modal button.blue {
  background-color: var(--blue);
}
.shades .modal button.grey {
  background-color: var(--c-boundary);
}
.shades .modal .version-fields {
  display: grid;
  grid-template-columns: auto 0.5fr 0.5fr;
  grid-template-rows: auto auto auto;
  grid-column-gap: var(--p);
  grid-row-gap: var(--p);
  margin-bottom: calc(2*var(--p));
}
.shades .modal .version-fields.existing-only {
  grid-template-columns: auto 1fr;
}
.shades .modal .version-fields.existing-only .new {
  display: none;
}
.shades .modal .version-fields .colHead {
  grid-row: 1/2;
  font-size: 0.8rem;
  font-weight: 700;
}
.shades .modal .version-fields .colHead.c1 {
  grid-column: 2/3;
}
.shades .modal .version-fields .colHead.c2 {
  grid-column: 3/4;
}
.shades .modal .version-fields .rowHead {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--c-muted);
  text-align: right;
}
.shades .modal .version-fields input, .shades .modal .version-fields textarea {
  border: none;
  padding: 5px;
  box-sizing: border-box;
  background-color: var(--c-boundary);
}
.shades .modal .version-fields textarea {
  min-height: 100px;
  resize: vertical;
}
.shades .modal .version-fields label {
  display: none;
}
.shades .modal .advanced {
  box-sizing: border-box;
  width: calc(100% + 4*var(--p));
  margin: 0 0 0 calc(-2*var(--p));
  padding: calc(2*var(--p));
  border-top: var(--standard-border);
}
.shades .modal .advanced .select {
  border: 1px solid var(--grey);
}
.shades .modal .advanced .select .input .selected {
  word-break: normal;
}
.shades .modal .advanced .select .dropdown {
  margin-left: -1px;
}
.shades .modal .warnings {
  background-color: #ff000011;
  box-sizing: border-box;
  width: calc(100% + 4*var(--p));
  margin: 0 0 calc(2*var(--p)) calc(-2*var(--p));
  padding: calc(2*var(--p));
  color: #b71c1c;
}
.shades .modal .warnings ul {
  margin: 0;
  padding-left: 16px;
}
.shades .modal .warnings ul li {
  margin-bottom: var(--p);
}
.shades .modal .warnings ul li:last-of-type {
  margin-bottom: 0;
}

.custom-scrollbar {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: var(--c-boundary);
  margin: 0;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 10px;
}
.custom-scrollbar.invisible-scrollbar {
  overflow-y: scroll;
}
.custom-scrollbar.invisible-scrollbar::-webkit-scrollbar-track {
  background-color: var(--c-boundary);
}
.custom-scrollbar.invisible-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* ============================================================================== */
/* ============================================================================== */
/* TRIPETTO ===================================================================== */
/* ============================================================================== */
.preview {
  padding: 0 32px;
  background-color: #fedb4a;
}
.preview iframe {
  height: 100% !important;
  min-height: 50vh;
}

div[data-block=tripetto-block-text] h2 label span,
div[data-block=tripetto-block-textarea] h2 label span,
div[data-block=tripetto-block-number] h2 label span,
div[data-block=tripetto-block-multiple-choice] h2 label span,
div[data-block=tripetto-block-dropdown] h2 label span,
div[data-block=tripetto-block-paragraph] h2 label span {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
div[data-block=tripetto-block-text] h3 span,
div[data-block=tripetto-block-textarea] h3 span,
div[data-block=tripetto-block-number] h3 span,
div[data-block=tripetto-block-multiple-choice] h3 span,
div[data-block=tripetto-block-dropdown] h3 span,
div[data-block=tripetto-block-paragraph] h3 span {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
div[data-block=tripetto-block-text] button, div[data-block=tripetto-block-text] select, div[data-block=tripetto-block-text] input, div[data-block=tripetto-block-text] textarea,
div[data-block=tripetto-block-textarea] button,
div[data-block=tripetto-block-textarea] select,
div[data-block=tripetto-block-textarea] input,
div[data-block=tripetto-block-textarea] textarea,
div[data-block=tripetto-block-number] button,
div[data-block=tripetto-block-number] select,
div[data-block=tripetto-block-number] input,
div[data-block=tripetto-block-number] textarea,
div[data-block=tripetto-block-multiple-choice] button,
div[data-block=tripetto-block-multiple-choice] select,
div[data-block=tripetto-block-multiple-choice] input,
div[data-block=tripetto-block-multiple-choice] textarea,
div[data-block=tripetto-block-dropdown] button,
div[data-block=tripetto-block-dropdown] select,
div[data-block=tripetto-block-dropdown] input,
div[data-block=tripetto-block-dropdown] textarea,
div[data-block=tripetto-block-paragraph] button,
div[data-block=tripetto-block-paragraph] select,
div[data-block=tripetto-block-paragraph] input,
div[data-block=tripetto-block-paragraph] textarea {
  width: 100%;
  padding: 0.25rem 0.5rem 0.2rem 0.5rem;
  border-color: #00000066;
  border-radius: 5px;
}
div[data-block=tripetto-block-text] nav button,
div[data-block=tripetto-block-textarea] nav button,
div[data-block=tripetto-block-number] nav button,
div[data-block=tripetto-block-multiple-choice] nav button,
div[data-block=tripetto-block-dropdown] nav button,
div[data-block=tripetto-block-paragraph] nav button {
  width: auto;
  padding: 0.25rem 0.75rem 0.2rem 0.5rem;
}

div[data-block=tripetto-block-paragraph] small {
  font-size: 0.7rem;
  line-height: 0.9rem;
}

/* ============================================================================== */
/* ============================================================================== */
/* CUSTOM BUILT DROPDOWN ======================================================== */
/* ============================================================================== */
.curtain {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff44;
}

.select {
  position: relative;
  width: 100%;
  height: 100%;
}
.select .input {
  display: grid;
  grid-template-columns: 1fr auto auto;
  box-sizing: border-box;
  height: 100%;
  background-color: #fcfcfc;
  cursor: pointer;
}
.select .input .selected {
  display: grid;
  align-items: center;
  padding: var(--h) var(--p);
  font-size: 14px;
  word-break: break-all;
}
.select .input .multiselected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 var(--h) var(--h) 0;
}
.select .input .multiselected .value {
  display: grid;
  grid-template-columns: auto auto;
  background-color: var(--c-highlight);
  margin: var(--h) 0 0 var(--h);
  border-radius: var(--h);
}
.select .input .multiselected .value .label {
  padding: var(--q) var(--h);
  font-size: 0.8rem;
  cursor: default;
}
.select .input .multiselected .value .unselect {
  padding: var(--q) var(--h);
  display: grid;
  align-items: center;
  border-left: 1px solid #00000022;
  color: #00000044;
}
.select .input .multiselected .value .unselect:hover {
  color: black;
}
.select .input .icon {
  padding: var(--h);
  display: grid;
  align-items: center;
  color: var(--c-muted);
}
.select .input .icon:hover, .select .input .icon.black {
  color: black;
}
.select .dropdown {
  display: block;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  font-size: 14px;
}
.select .dropdown input {
  display: block;
  padding: var(--p) !important;
  border: 0;
  width: 100%;
  border-bottom: 1px solid #bcbcbc !important;
  background-color: #fff !important;
}
.select .dropdown .options {
  max-height: 200px;
  overflow-y: scroll;
}
.select .dropdown .options .heading {
  padding: var(--h) var(--p);
  background-color: var(--c-boundary);
  font-weight: 700;
}
.select .dropdown .options .option {
  padding: var(--p);
}
.select .dropdown .options .option:hover {
  background-color: var(--c-boundary);
  cursor: pointer;
}
.select .dropdown .options .option.active {
  background-color: var(--c-highlight);
}
.select .dropdown .options .option.disabled {
  color: var(--c-muted);
  cursor: default;
}
.select .dropdown .options .option.disabled:hover {
  background-color: transparent;
}

.field-box > .select .input {
  background-color: var(--c-boundary);
}
/*# sourceMappingURL=index.6b29686d.css.map */
