
$colour-boundary: #f6f6f6;

:root {
	--p: 10px;
	--h: calc(var(--p) / 2);
	--q: calc(var(--p) / 4);

	--c-highlight: #fedb4a;
	--c-boundary: #f6f6f6;
	--c-muted: #888;

	--green: #8bc34a;
	--blue: #64b5f6;
	--red: #f44336;
	--purple: #673ab7;
	--grey: #cfd8dc;
	--orange: #ff8f00;
	--teal: #009688;

	--standard-border: 2px solid var(--c-boundary);
	--button-size: calc(1rem * (5/6));
}

html {
	box-sizing: border-box;

	font-family: sans-serif;
	font-size: 16px;

	overflow-y: hidden;
}

body {
	display: grid;
	grid-template-columns: 20px auto 1fr auto;
	grid-template-rows: calc(100vh - 20px) 20px;

	max-height: 100vh;

	margin: 0;
}

.hide {
	display: none !important;
}

.invisible {
	visibility: hidden !important;
}

button, input, textarea {
	&:focus {
		outline: none;
	}
}

button {
	font-size: var(--button-size);
	cursor: pointer;
	background-color: transparent;
}

.login-fields {
	grid-column: 1 / 4;
	grid-row: 1 / 4;
	justify-self: center;
	align-self: center;
	
	width: 400px;
	max-width: 400px;
	
	border: var(--standard-border);
	box-sizing: border-box;

	padding: calc(var(--p) * 2);

	p {
		margin-top: 0;
	}

	label, input, button {
		display: block;
	}

	label {
		font-size: .9rem;
	}

	input {
		width: 100%;
		border: none;
		margin: 5px 0 20px 0;
		padding: 5px;
		box-sizing: border-box;
		background-color: var(--c-boundary);
	}

	button {
		float: right;
		background-color: var(--c-highlight);
		border: 0;
		padding: 5px 10px;
		
		&.forgot-password {
			float: left;
			background-color: transparent;
			padding: 5px 0;
			color: var(--c-muted);
			text-decoration: underline;
		}

		&:hover {
			text-decoration: underline;
		}
	}
	

	.login-error {
		display: block;
		clear: both;
		color: red;
		font-weight: 700;
		text-align: center;
		font-size: .9rem;
		text-transform: uppercase;
		padding-top: 10px;
	}

}

div.main-menu-toggle {
	grid-column: 1 / 2;
	grid-row: 1 / 3;
	background-color: var(--c-boundary);
	cursor: pointer;

	&:hover {
		background-color: var(--c-highlight);
	}
}


nav.main-menu {
	grid-column: 2 / 3;
	grid-row: 1 / 3;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 200px;
	box-sizing: border-box;

	font-size: var(--button-size);

	border-right: var(--standard-border);
	padding: var(--p);
	
	button, a {
		display: block;

		padding: 0 0 5px 0;
		margin: 0;
		width: 100%;
		text-align: left;
		border: 0;
		background-color: transparent;
		text-decoration: none;
		color: #000;
		
		&.indent {
			padding-left: var(--p);
		}

		&:hover, &.here {
			span {
				background-color: var(--c-highlight);
			}
			// text-decoration: underline;
		}
	}

	hr {
		display: block;
		height: 2px;
		margin: 5px 0 10px 0;
		padding: 0;
		border: none;
		background-color: var(--c-boundary);
	}
}

div.main {
	grid-column: 3 / 4;
	grid-row: 1 / 3;

	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto auto auto 1fr;

	align-self: start;

	font-size: .9rem;

	height: 100vh;
	
	.controls {
		grid-column: 1 / 2;
		grid-row: 1 / 2;

		display: grid;
		grid-template-rows: auto;
		grid-template-columns: auto 1fr auto auto auto auto;

		// padding: var(--p);
		border-bottom: var(--standard-border);

		input[type="text"] {
			border: 0;
			padding: var(--p);
			width: 100%;
			box-sizing: border-box;
		}

		.counts {
			padding: var(--p);
			font-family: monospace;
			font-weight: 600;

			span {
				font-weight: 300;
			}
		}

		div.pagination {
			padding: var(--p) 0;
			font-size: var(--button-size);
		}

		button {
			border: none;
			padding: var(--p);

			&.green {
				background-color: var(--green);
			}

			&.grey {
				background-color: var(--grey);
			}

			&.filter {
				display: flex;
				flex-direction: column;
				align-items: end;

				&:hover {
					text-decoration: none;
					background-color: var(--c-boundary);
				}

				&.active {
					background-color: var(--c-boundary);
				}
			}

			&.pagination {
				color: var(--grey);

				&:hover {
					text-decoration: none;
					color: black;
				}
			}

			&:hover {
				text-decoration: underline;
			}
		}

	}

	.filters {
		grid-row: 2 / 3;
		
		display: grid;
		grid-template-columns: .5fr .5fr;

		.filter {
			padding: var(--p);

			label {
				display: block;
				text-transform: uppercase;
				font-size: .8rem;
				letter-spacing: 0px;
				color: var(--c-muted);
			}
			
			.select {
				.input {
					background-color: var(--c-boundary);
				}
			}

			.text {
				display: grid;
				grid-template-columns: 1fr auto;
				grid-template-rows: auto;

				& > input[type="text"] {
					background-color: var(--c-boundary);
					border: 0;
					padding: var(--h);
					display: block;
					width: calc(100% - 2*var(--h));
				}

				button {
					border: none;
					font-size: 1rem;
					display: flex;
					align-items: flex-end;
					justify-content: end;
					background-color: var(--c-boundary);
					padding: var(--h) var(--p) 0 var(--p);

					&:hover {
						background-color: #fedb4a;
					}
				}
			}

		}
	}

	.row {
		&.clickable {
			cursor: pointer;
		}
	}

	.table {
		grid-column: 1 / 2;
		
		// align-self: start;

		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr;

		&.table-header {
			grid-row: 3 / 4;
		}
		&.main-collection {
			grid-row: 4 / 5;
		}

		.row {
			border-bottom: 1px solid var(--c-boundary);

			&:hover {
				background-color: var(--c-boundary);
			}

			display: grid;
			grid-template-columns: 1fr auto;
			grid-template-rows: auto;

			&.archived {
				background-image: linear-gradient(45deg, var(--c-highlight) 8.33%, #ffffff00 8.33%, #ffffff00 50%, var(--c-highlight) 50%, var(--c-highlight) 58.33%, #ffffff00 58.33%, #ffffff00 100%);
				background-size: 6.00px 6.00px;
			}

			.key {
				display: none;
			}

			.col {
				padding: var(--p);
				border-right: 1px solid var(--c-boundary);
				overflow-wrap: break-word;

				&.monospace {
					font-family: monospace;
				}

				&.jar-of-jellybeans {
					padding-top: calc(var(--p) - 3px);
					padding-bottom: 0;
					padding-left: calc(var(--p) - 6px);
					padding-right: calc(var(--p) - 6px);
					
					.jellybean {
						display: inline-block;
						margin: 0 calc(var(--p) - 3px) calc(var(--p) - 3px) 0;
						border: 1px solid var(--c-boundary);
						border-radius: 5px;
						padding: 2px 5px;
						background-color: var(--c-boundary);
					}
				}

			}

			.row-data {
				display: grid;
				grid-template-rows: auto;
			}

			.row-buttons {
				display: grid;
				grid-template-columns: repeat(4, auto);
				grid-template-rows: auto;
				
				button {
					border: none;
					padding: var(--p);

					&.blue {
						background-color: var(--blue);
					}
					&.red {
						background-color: var(--red);
						// color: #fff;
					}

					&:hover {
						text-decoration: underline;
					}
				}
			}

			&.being-edited {
				background-color: var(--c-highlight);
			}

		}

		.header-row {
			background-color: var(--c-boundary);
			font-size: .8rem;
			font-weight: 700;
			text-transform: uppercase;
			border: none;

			.col {
				padding: calc(var(--p) / 2) var(--p);
				border-right: 1px solid var(--c-boundary);
			}

			.row-buttons {
				button {
					padding: calc(var(--p) / 2) var(--p);
				}
			}

		}
	}

	.masonry {
		
		grid-row: 4 / 5;

		padding: var(--p) 0 0 var(--p);
		// min-height: 500px;

		background-color: var(--c-boundary);

		.masonry-wrapper {
			// margin: -1px;
			// width: calc(100% + 1px);
		}

		.row-sizer {
			display: block;
			width: calc(100% / 4);
		}

		.row {
			display: block;
			box-sizing: border-box;
			float: left;
			
			width: calc(((100% - 4 * var(--p))/ 4));
			
			padding: var(--p);
			
			// border: 1px solid var(--c-boundary);
			// border-right: var(--p) solid var(--c-boundary);
			// border-bottom: var(--p) solid var(--c-boundary);
			border: 1px solid #fff;
			margin-right: var(--p);
			margin-bottom: var(--p);
			
			background-color: #fff;

			&:hover {
				// background-color: var(--c-boundary);
				border: 1px solid #ddd;
			}

			&.archived {
				background-image: linear-gradient(45deg, var(--c-highlight) 8.33%, #ffffff00 8.33%, #ffffff00 50%, var(--c-highlight) 50%, var(--c-highlight) 58.33%, #ffffff00 58.33%, #ffffff00 100%);
				background-size: 6.00px 6.00px;
			}

			.key {
				grid-column: 1 / 2;
				border-width: 1px 0 0 0;
				border-color: var(--c-boundary);
				border-style: solid;
				padding: calc(var(--p) / 2);

				display: flex;
				align-items: flex-end;
				justify-content: flex-end;

				font-size: .7rem;
				text-transform: uppercase;
				font-family: monospace;

				line-height: .9rem;

				&:first-of-type {
					display: none;
				}
			}

			.col {
				grid-column: 2 / 3;
				border-width: 1px 0 0 1px;
				border-color: var(--c-boundary);
				border-style: solid;
				padding: calc(var(--p) / 2);
				min-width: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				// word-break: break-all;

				&::before {
					content: attr(data-col);
				}

				&:first-of-type {
					grid-column: 1 / 3;
					margin-bottom: var(--p);
					border: none;
					font-weight: 700;
					word-wrap: break-all;
				}

				&.monospace {
					font-family: monospace;
				}
				&.bold {
					font-weight: 700;
				}
				
			}

			.row-data {
				display: grid;
				grid-template-columns: auto 1fr;

				border-bottom: 1px solid var(--c-boundary);
			}

			.row-buttons {
				display: flex;
				justify-content: flex-end;
				padding-top: var(--p);
				
				opacity: 0;

				button {
					border: none;
					padding: var(--p) 0 0 var(--p);

					&.blue {
						background-color: var(--blue);
					}
					&.red {
						background-color: var(--red);
						// color: #fff;
					}

					&:hover {
						text-decoration: underline;
					}
				}
			}

			&:hover {
				.row-buttons {
					opacity: 1;
				}
			}

			&.being-edited {
				// background-color: var(--c-highlight);
				border: 1px solid #000;
			}

		}
	}
}

aside {
	box-sizing: border-box;
	width: 40vw;
	max-width: 700px;
	
	border-left: var(--standard-border);

	grid-column: 4 / 5;
	grid-row: 1 / 3;

	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto 1fr auto;

	font-size: .9rem;
	
	.controls {
		grid-column: 1 / 2;
		// grid-row: 1 / 2;

		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr auto;

		// padding: var(--p);
		border-top: var(--standard-border);
		border-bottom: var(--standard-border);

		&:first-of-type {
			border-top: none;
		}

		h1 {
			padding: var(--p);
			font-weight: 600;
			font-size: var(--button-size);
			margin: 0;
		}

		.buttons {
			display: flex;
		}

		button {
			border: none;
			padding: var(--p);

			&.green {
				background-color: var(--green);
			}
			&.blue {
				background-color: var(--blue);
			}

			&:hover {
				text-decoration: underline;
			}
		}

	}

	.fields {
		padding: var(--p);
		
		& > div > input[type="text"], input[type="date"], input[type="color"] {
			display: block;
			width: 100%;
			box-sizing: border-box;
			border-radius: 0;
			border: 1px solid var(--c-boundary);
			// border-bottom: 1px solid var(--c-muted);
			background-color: var(--c-boundary);
			padding: 5px;
			font-size: 14px;
		}

		& > div > input[type="color"] {
			height: calc(4 * var(--p));
		}

		& > div > .switch-container {
			display: block;
			box-sizing: border-box;
			width: 100%;
			border: 1px solid var(--c-boundary);
			background-color: var(--c-boundary);
			padding: 5px;
		}
		
		select {
			display: block;
			border: 0;
			background-color: #fcfcfc;
			padding: 5px;
			font-size: 14px;

			&:focus {
				outline: none;
			}
		}

		textarea {
			display: block;
			width: 100%;
			box-sizing: border-box;
			border-radius: 0;
			border: 1px solid var(--c-boundary);
			// border-bottom: 1px solid var(--c-muted);
			background-color: var(--c-boundary);
			padding: 5px;
			font-size: 14px;
			height: 100px;
			resize: vertical;

			&:focus {
				outline: none;
			}
		}

		label {
			display: block;
			text-transform: uppercase;
			font-size: .8rem;
			letter-spacing: 0px;
			color: var(--c-muted);
			margin-bottom: 10px;

			button.inline {
				display: inline;
				border: 0;
				padding: 0;
				font-size: inherit;
				text-decoration: underline;
			}
		}

		.error {
			& > input[type="text"], input[type="date"], input[type="date"] {
				border: 1px solid var(--red);
			}

			& > .select {
				border: 1px solid var(--red);	
			}

			& > label {
				color: var(--red);
			}
		}

		.field-box.monospace {
			input {
				font-family: monospace;
			}
		}

		.sortable {
			background-color: var(--c-boundary);

			.sortable-drag {
				.choices__list {
					display: none;
				}
			}

			.item {
				display: flex;
				flex-flow: wrap;

				// border-bottom: 1px solid #fff;
				&:last-of-type {
					border-bottom: none;
				}
				
				.item-content {
					display: grid;
					width: 100%;
					grid-template-columns: 1fr auto auto;

					.content, .group {
						padding: var(--p) var(--p) calc(var(--p)/2) var(--p);
						flex-grow: 1;
						display: grid;
						grid-column-gap: 5px;
						grid-row-gap: 5px;

						.subfield-box {
							display: grid;
							grid-template-columns: 1fr;

							margin-bottom: calc(var(--p) / 2);

							label {
								// margin: 0 10px 0 0;
								// align-self: center;
								// text-align: right;
								display: none;
							}

							input[type="text"] {
								// display: block;
								width: 100%;
								box-sizing: border-box;
								border-radius: 0;
								border: 1px solid #fcfcfc;
								background-color: #fcfcfc;
								padding: 5px;
								font-size: 14px;

								&::placeholder {
									color: #cdcdcd;
								}

								&.choices__input {
									padding: 10px;
									border-bottom: 1px solid #d0d0d0;
								}
							}

							.choices {
								.choices__inner {
									background-color: #fcfcfc;
									.choices__list {
										padding-right: 45px;
									}
								}
							}

							.item .item-content {
								padding-bottom: var(--h);

								.content {
									display: grid;
									grid-template-columns: repeat(20, .05fr);
									padding: 0;
								}

							}
							
							.insert {
								background-color: #fcfcfc;

								&:hover {
									color: #fcfcfc;
									background-color: #141414;
								}
							}

							.buttons {
								.button {
									padding: var(--h) var(--p);
								}
							}
						}
					}

					.group {
						padding: var(--p);
						border-top: 1px solid #fff;
						grid-column: 1 / 4;
						display: grid;
						grid-template-columns: repeat(20, .05fr);

						.subfield-box {
							margin: 0;
						}

						&.logic {
							border: 1px solid #673ab7;
							
							.insert:hover {
								background-color: #673ab7;
							}
						}

						&.required {
							border: 1px solid var(--orange);
						}

						&.contact {
							border: 1px solid var(--teal);
						}
					}

					.buttons {

						display: flex;

						.button {
							padding: var(--p);
							display: flex;
							align-items: center;
							cursor: pointer;
						}

						.delete {
							color: var(--red);
							&:hover {
								background-color: var(--red);
								color: #fff;
							}
						}

						.logic {
							color: var(--purple);
							&:hover, &.active {
								background-color: var(--purple);
								color: #fff;
							}
						}

						.required {
							color: var(--orange);
							&:hover, &.active {
								background-color: var(--orange);
								color: #fff;
							}
						}

						.contact {
							color: var(--teal);
							&:hover, &.active {
								background-color: var(--teal);
								color: #fff;
							}
						}
					}


					.handle {
						width: calc(3 * var(--p));
						background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==) repeat;
						border: 5px solid var(--c-boundary);
						box-sizing: border-box;

						cursor: grab;

						// &:hover {
						// 	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHklEQVQYV2NkYGD4z4AAjIxIAmA2TAAuAWKAAFwbAM+UBgIeku7iAAAAAElFTkSuQmCC) repeat;
						// }
					}

				}

				.insert {
					width: 100%;
				}

			}

			.insert {
				// border-bottom: 1px solid #fff;
				text-align: center;
				background-color: #fff;
				cursor: pointer;

				&:hover {
					background-color: var(--c-highlight);
				}
			}
		}
		
		select {
			width: 100%;
			height: 100%;
		}
	
	}

	.messages {
		
		.message {
			padding: var(--p);
			border: 1px solid var(--red);
			font-weight: 700;
			text-align: center;
			color: var(--red);
			background-color: #f4433644;

		}

	}
}

.shades {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99;
	background-color: #ffffffee;

	.modal {
		justify-self: center;
		align-self: center;
		
		width: 400px;
		max-width: 400px;

		&.large {
			width: 700px;
			max-width: 700px;
		}
		
		background-color: #fff;
		border: var(--standard-border);
		box-sizing: border-box;

		padding: calc(var(--p) * 2);

		p {
			margin-top: 0;
		}

		button {
			float: right;
			background-color: var(--c-highlight);
			border: 0;
			padding: 5px 10px;
			margin-left: var(--p);
			
			&.muted {
				float: left;
				background-color: transparent;
				padding: 5px 0;
				color: var(--c-muted);
				text-decoration: underline;
				margin-left: 0;
			}

			&:hover {
				text-decoration: underline;
			}

			&.red {
				background-color: var(--red);
			}
			&.blue {
				background-color: var(--blue);
			}
			&.grey {
				background-color: var(--c-boundary);
			}
		}

		.version-fields {
			display: grid;
			grid-template-columns: auto .5fr .5fr;
			grid-template-rows: auto auto auto;
			grid-column-gap: var(--p);
			grid-row-gap: var(--p);

			margin-bottom: calc(2*var(--p));

			&.existing-only {
				grid-template-columns: auto 1fr;

				.new {
					display: none;
				}
			}

			.colHead {
				grid-row: 1 / 2;

				font-size: .8rem;
				font-weight: 700;
				// text-align: center;

				&.c1 {
					grid-column: 2 / 3;
				}
				&.c2 {
					grid-column: 3 / 4;
				}
			}

			.rowHead {
				text-transform: uppercase;
				font-size: .8rem;
				letter-spacing: 0px;
				color: var(--c-muted);
				text-align: right;

				// align-self: center;	
			}

			input, textarea {
				border: none;
				padding: 5px;
				box-sizing: border-box;
				background-color: var(--c-boundary);
			}

			textarea {
				min-height: 100px;
				resize: vertical;
			}

			label {
				display: none;
			}
		}

		.advanced {
			box-sizing: border-box;
			width: calc(100% + 4*var(--p));
			margin: 0 0 0 calc(-2*var(--p));
			padding: calc(2*var(--p));
			border-top: var(--standard-border);

			.select {

				border: 1px solid var(--grey);

				.input {
					.selected {
						word-break: normal;
					}
				}

				.dropdown {
					margin-left: -1px;
				}
			}
		}

		.warnings {
			background-color: #ff000011;
			box-sizing: border-box;
			width: calc(100% + 4*var(--p));
			margin: 0 0 calc(2*var(--p)) calc(-2*var(--p));
			padding: calc(2*var(--p));

			color: #b71c1c;
			
			ul {
				margin: 0;
				padding-left: 16px;

				li {
					margin-bottom: var(--p);

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

		}
	}

}

.custom-scrollbar {
	overflow-y: scroll;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
	
	&::-webkit-scrollbar-track {
		background-color: var(--c-boundary);
		// border-radius: 10px;
		margin: 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ddd;
		border-radius: 10px;
	}

	&.invisible-scrollbar {
		overflow-y: scroll;

		&::-webkit-scrollbar-track {
			background-color: var(--c-boundary);
		}

		&::-webkit-scrollbar-thumb {
			background-color: transparent;
		}		
	}
}

/* ============================================================================== */
/* ============================================================================== */
/* TRIPETTO ===================================================================== */
/* ============================================================================== */

.preview {
	padding: 0 32px;
	background-color: #fedb4a;

	iframe {
		height: 100% !important;
		min-height: 50vh
	}
}

div[data-block="tripetto-block-text"],
div[data-block="tripetto-block-textarea"],
div[data-block="tripetto-block-number"],
div[data-block="tripetto-block-multiple-choice"],
div[data-block="tripetto-block-dropdown"],
div[data-block="tripetto-block-paragraph"], {
	// background-color: red;

	h2 label span {
		font-weight: 400;
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	h3 span {
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 1.4rem;	
	}

	button, select, input, textarea {
		width: 100%;
		padding: 0.25rem 0.5rem 0.2rem 0.5rem;
		border-color: #00000066;
		border-radius: 5px;
	}

	nav {
		button {
			width: auto;
			padding: 0.25rem 0.75rem 0.2rem 0.5rem;
		}
	}
}

div[data-block="tripetto-block-paragraph"] {

	small {
		font-size: .7rem;
		line-height: .9rem;
	}

}

/* ============================================================================== */
/* ============================================================================== */
/* CUSTOM BUILT DROPDOWN ======================================================== */
/* ============================================================================== */

.curtain {
	display: block;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #ffffff44;
	// -webkit-backdrop-filter: blur(3px);
	// backdrop-filter: blur(3px);
}

.select {

	position: relative;
	width: 100%;
	height: 100%;

	.input {
		display: grid;
		grid-template-columns: 1fr auto auto;

		box-sizing: border-box;
		height: 100%;

		background-color: #fcfcfc;
		cursor: pointer;

		.selected {
			display: grid;
			align-items: center;
			padding: var(--h) var(--p);
			font-size: 14px;
			word-break: break-all;
		}

		.multiselected {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			padding: 0 var(--h) var(--h) 0;

			.value {
				display: grid;
				grid-template-columns: auto auto;
				background-color: var(--c-highlight);
				margin: var(--h) 0 0 var(--h);

				border-radius: var(--h);

				.label {
					padding: var(--q) var(--h);
					font-size: .8rem;
					cursor: default;
				}

				.unselect {
					padding: var(--q) var(--h);
					display: grid;
					align-items: center;
					border-left: 1px solid #00000022;
					color: #00000044;

					&:hover {
						color: black;
					}
				}
			}

		}

		.icon {
			padding: var(--h);
			display: grid;
			align-items: center;

			color: var(--c-muted);

			&:hover, &.black {
				color: black;
			}
		}
	}

	.dropdown {
		display: block;
		position: absolute;
		width: 100%;

		overflow-x: hidden;
		overflow-y: hidden;

		

		z-index: 1;

		background-color: #fff;
		border: 1px solid #bcbcbc;

		font-size: 14px;

		input {
			display: block;
			padding: var(--p) !important;
			border: 0;
			width: 100%;
			border-bottom: 1px solid #bcbcbc !important;
			background-color: #fff !important;

		}

		.options {
			max-height: 200px;
			overflow-y: scroll;

			.heading {
				padding: var(--h) var(--p);
				background-color: var(--c-boundary);
				font-weight: 700;
			}

			.option {
				padding: var(--p);

				&:hover {
					background-color: var(--c-boundary);
					cursor: pointer;
				}

				&.active {
					background-color: var(--c-highlight);
				}

				&.disabled {
					color: var(--c-muted);
					cursor: default;

					&:hover {
						background-color: transparent;
					}
				}
			}
		}


	}

}

.field-box > .select .input {
	background-color: var(--c-boundary);
}